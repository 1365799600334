/* styles/CookiePopup.module.css */
.cookiePopup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1a1a1a;
    color: white;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 400px;
    text-align: center;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .cookieText {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .privacyLink {
    color: #f4a431;
    text-decoration: underline;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  
  .button {
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
    width: 100%;
  }
  
  .acceptButton {
    background-color: #f4a431;
    color: white;
  }
  
  .acceptButton:hover {
    background-color: rgba(244, 164, 49, 0.8);
  }
  
  .rejectButton {
    background-color: #333;
    color: white;
  }
  
  .rejectButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .settingsLink {
    font-size: 12px;
    color: #f4a431;
    text-decoration: underline;
  }
  
  /* Responsywność */
  @media (max-width: 480px) {
    .cookiePopup {
      width: 90%;
      padding: 16px;
      font-size: 12px;
    }
  
    .button {
      font-size: 12px;
      padding: 8px 14px;
    }
  }
  