.container {
    width: 100%;
    margin: 0 auto;
    padding: 6rem 2rem;
    background: linear-gradient(135deg, #1a1a1a 0%, #242424 100%);
    min-height: 100vh;
  }
  
  .header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .title {
    margin-top: 6rem;
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  
  .titleHighlight {
    background: linear-gradient(135deg, #ffffff, #fca311);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
  }
  
  .titleHighlight::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, transparent, #fca311, transparent);
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 2.5rem;
  }
  
  .card {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
    border-color: rgba(252, 163, 17, 0.3);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .imageLink {
    display: block;
    text-decoration: none;
  }
  
  .imageWrapper {
    position: relative;
    height: 240px;
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .card:hover .image {
    transform: scale(1.05);
  }
  
  .content {
    padding: 2rem;
  }
  
  .meta {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .readTime {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
  }
  
  .date {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
  }
  
  .titleLink {
    text-decoration: none;
    color: inherit;
  }
  
  .cardTitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 0 1rem;
    color: #ffffff;
    line-height: 1.4;
    transition: color 0.3s ease;
  }
  
  .card:hover .cardTitle {
    color: #fca311;
  }
  
  .excerpt {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 1.5rem;
    line-height: 1.6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .readMore {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #fca311;
    text-decoration: none;
    font-weight: 500;
    transition: gap 0.3s ease;
  }
  
  .card:hover .readMore {
    gap: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .grid {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 4rem 1.5rem;
    }
  
    .title {
      font-size: 2.5rem;
    }
  
    .grid {
      grid-template-columns: 1fr;
    }
  
    .imageWrapper {
      height: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 3rem 1rem;
    }
  
    .meta {
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;
    }
  }
  .categories {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  
  .category {
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    backdrop-filter: blur(4px);
  }

  /* Dodaj te style do istniejącego pliku Blog.module.css */

.sectionTitle {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  margin: 2rem 0;
  text-align: center;
}

.localPostsSection {
  margin-bottom: 4rem;
  padding: 2rem;
  background: rgba(252, 163, 17, 0.05);
  border-radius: 1.5rem;
  border: 1px solid rgba(252, 163, 17, 0.1);
}

.localCard {
  border-color: rgba(252, 163, 17, 0.3);
  background: rgba(252, 163, 17, 0.05);
}

.localBadge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.25rem 0.75rem;
  background: #fca311;
  color: #000000;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  z-index: 1;
}

.ctaSection {
  margin: 5rem 0 3rem;
  padding: 3rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.ctaSection h2 {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.ctaSection p {
  color: rgba(255, 255, 255, 0.7);
  max-width: 600px;
  margin: 0 auto 2rem;
}

.ctaButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.primaryButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #fca311;
  color: #000000;
  border-radius: 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.primaryButton:hover {
  background: #f59f00;
  gap: 0.75rem;
  transform: translateY(-2px);
}

.secondaryButton {
  padding: 0.75rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  transition: all 0.3s ease;
}

.secondaryButton:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

/* Responsywność */
@media (max-width: 640px) {
  .ctaSection {
    padding: 2rem 1rem;
  }
  
  .ctaButtons {
    flex-direction: column;
  }
  
  .primaryButton, .secondaryButton {
    width: 100%;
    justify-content: center;
  }
  
  .localPostsSection {
    padding: 1.5rem 1rem;
  }
}