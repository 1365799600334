  /* styles/home.module.css */
  .container {
    padding-top: 5rem;
    min-height: 100vh;
    background: #000000;
  }

  .hero {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;
    max-width: 1400px;
    margin: 0 auto;
    flex-direction: row-reverse;
  }

  .leftContent {
    flex: 1;
    max-width: 600px;
  }

  .title {
    font-size: 4.5rem;
    font-weight: 800;
    line-height: 1.1;
    color: #FFFFFF;
  }

  .titleSecondLine {
    display: block;
    margin-top: 0.5rem;
  }

  .subtitle {
    color: #FFFFFF;
    font-size: 1.5rem;
    margin: 2rem 0;
  }

  .badges {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .badge {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .badge:hover {
    background: rgba(255, 149, 0, 0.2);
  }

  .ctaButton {
    padding: 10px 20px;
    border: none;
    font-size: 17px;
    color: #fff;
    border-radius: 7px;
    letter-spacing: 4px;
    font-weight: 700;
    text-transform: uppercase;
    background: #Fca311; /* Kolor przycisku */
    box-shadow: 0 0 10px #Fca311; /* Delikatniejszy cień */
    animation: shake 1s ease-in-out infinite, glow 2s ease-in-out infinite, scaleUpDown 2s ease-in-out infinite; /* Animacje */
    cursor: pointer; /* Zmieniamy kursor, żeby sugerować, że przycisk jest interaktywny */
    transition: box-shadow 0.3s ease-in-out; /* Zmiana cienia przy hover */
  }

  /* Animacja trzęsienia się (shake) */
  @keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
  }

  /* Delikatniejsza animacja świecenia (glow) */
  @keyframes glow {
    0%, 100% {
        box-shadow: 0 0 10px #Fca311, 0 0 20px #Fca311, 0 0 40px #Fca311; /* Subtelniejszy cień */
    }
    50% {
        box-shadow: 0 0 10px #Fca311, 0 0 20px #FF7A00, 0 0 40px #FF7A00; /* Jaśniejszy cień w połowie animacji */
    }
  }

  /* Animacja powiększania i zmniejszania (scaleUpDown) */
  @keyframes scaleUpDown {
    0%, 100% {
        transform: scale(1); /* Normalny rozmiar */
    }
    50% {
        transform: scale(1.1); /* Powiększenie */
    }
  }

  /* Efekt zmiany cienia przy hover */
  .ctaButton:hover {
    box-shadow: 0 0 5px #Fca311,
                0 0 15px #Fca311,
                0 0 25px #Fca311,
                0 0 50px #Fca311;
  }




  .imageContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh; /* Pełna wysokość kontenera */
    position: relative; /* Dla pozycjonowania obrazka */
  }
  .imageWrapper {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .trainerImage {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Zmiana z cover na contain */
    object-position: right center; /* Pozycjonowanie po prawej stronie */
  }


  .socialLinks {
    margin-right: 55%;
  justify-content: right;
    display: flex;
    gap: 2rem;
    margin-top: 3rem;
  }

  .socialIcon {
    color: #FFFFFF;
    transform: scale(1.5);
    transition: all 0.4s ease;
    opacity: 0.9;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% { transform: scale(1.5); }
    50% { transform: scale(1.6); }
    100% { transform: scale(1.5); }
  }

  .socialIcon:nth-child(1):hover {
    color: #E4405F; /* Instagram brand color */
    opacity: 1;
    animation: none;
    transform: scale(1.7);
  }

  .socialIcon:nth-child(2):hover {
    color: #FF0000; /* YouTube brand color */
    opacity: 1;
    animation: none;
    transform: scale(1.7);
  }

  @media (max-width: 768px) {
    .hero {
      flex-direction: column;
      padding: 2rem;
      text-align: center;
      gap: 2rem;
    }

    .leftContent {
      max-width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      font-size: 3rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }

    .badges {
      flex-direction: column;
      width: 100%;
      max-width: 300px;
    }

    .badge {
      width: 100%;
      justify-content: center;
    }

    .ctaButton {
      width: 100%;
      justify-content: center;
      max-width: 300px;
    }

    .imageContainer {
        height: auto; /* Automatyczna wysokość na mobile */
        width: 100%;
        justify-content: center;
      }

      .trainerImage {
        height: auto; /* Automatyczna wysokość */
        max-height: 70vh; /* Maksymalna wysokość na mobile */
        width: auto; /* Automatyczna szerokość */
        object-position: center; /* Wycentrowanie na mobile */
      }
      .socialLinks {
        margin-right: 0;
        justify-content: center;
        gap: 3rem;
      }
      
      .socialIcon {
        transform: scale(1.8);
      }
      
      .socialIcon:hover {
        transform: scale(2);
      }
  }

  /* Dodatkowe style dla bardzo małych ekranów */
  @media (max-width: 480px) {
    .hero {
      padding: 5rem;
    }

    .title {
      font-size: 2.5rem;
    }

    .subtitle {
      font-size: 1rem;
      margin: 1rem 0;
    }

    .badges {
      margin-bottom: 1.5rem;
    }

    .trainerImage {
      height: 40vh;
    }
  }