.nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 20px 0;
  font-size:1.2rem;
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.brand {
  display: flex;
  flex-direction: column;
  line-height: 1;
  text-decoration: none;
}
.brandFirst {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 1px;
}
.brandSecond {
  font-size: 2rem;
  font-weight: 600;
  color: #FF9500;
  letter-spacing: 0.5px;
}
.menu {
  display: flex;
  gap: 48px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.menu li a:hover {
  color: #FF9500;
}

.mobileButton {
  display: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px;
}

.mobileMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.95);
  padding: 16px;
  margin-top: 8px;
  flex-direction: column;
  gap: 16px;
}

.mobileMenu a {
  color: white;
  text-decoration: none;
  padding: 12px 16px;
  transition: all 0.3s ease;
}

.mobileMenu a:hover {
  background: rgba(255, 149, 0, 0.1);
  color: #FF9500;
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .mobileButton {
    display: block;
  }

  .mobileMenu {
    display: flex;
  }
}