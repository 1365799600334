.section {
    width: 100%;
    background: linear-gradient(to bottom, #171717, #000000);
    padding: 6rem 1rem;
    overflow: hidden;
    margin-top: -60px;
    position: relative;
    z-index: 2;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    opacity: 0;
}

.visible .container {
    animation: fadeIn 0.5s ease-out forwards;
}

.title {
    font-size: clamp(2rem, 5vw, 3rem);
    font-weight: 800;
    text-align: center;
    margin-bottom: 4rem;
    color: #ffffff;
}

.grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
}

@media (min-width: 1024px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.problemsContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.problemCard {
    background: linear-gradient(to right, #1a1a1a, #222222);
    padding: 1.5rem;
    border-radius: 1rem;
    border-left: 4px solid #fca311;
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateX(-20px);
}

.visible .problemCard:nth-child(1) {
    animation: slideIn 0.5s ease-out 0.3s forwards;
}

.visible .problemCard:nth-child(2) {
    animation: slideIn 0.5s ease-out 0.6s forwards;
}

.visible .problemCard:nth-child(3) {
    animation: slideIn 0.5s ease-out 0.9s forwards;
}

.problemCard:hover {
    transform: translateX(10px);
    background: linear-gradient(to right, #222222, #2a2a2a);
}

.cardHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.75rem;
}

.icon {
    color: #fca311;
    flex-shrink: 0;
}

.cardTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
}

.cardText {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
}

.highlight {
    color: #fca311;
    font-weight: 500;
}

.solutionsCard {
    background: rgba(26, 26, 26, 0.5);
    padding: 2rem;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    opacity: 0;
}

.visible .solutionsCard {
    animation: fadeIn 0.5s ease-out 1.2s forwards;
}

.solutionsTitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fca311;
    margin-bottom: 1.5rem;
}

.solutionsList {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.solutionItem {
    display: flex;
    gap: 1rem;
    opacity: 0;
}

.visible .solutionItem:nth-child(1) {
    animation: fadeIn 0.5s ease-out 1.4s forwards;
}

.visible .solutionItem:nth-child(2) {
    animation: fadeIn 0.5s ease-out 1.6s forwards;
}

.visible .solutionItem:nth-child(3) {
    animation: fadeIn 0.5s ease-out 1.8s forwards;
}

.visible .solutionItem:nth-child(4) {
    animation: fadeIn 0.5s ease-out 2s forwards;
}

.solutionIcon {
    color: #fca311;
    flex-shrink: 0;
}

.solutionContent h4 {
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.solutionContent p {
    color: rgba(255, 255, 255, 0.6);
}

.nextSection {
    margin-top: 4rem;
    text-align: center;
    opacity: 0;
}

.visible .nextSection {
    animation: fadeIn 0.5s ease-out 2.2s forwards;
}

.nextSectionLink {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #fca311;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
}

.nextSectionLink:hover {
    gap: 1rem;
    transform: translateX(5px);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .section {
        padding: 3rem 1rem;
        margin-top: -40px;
    }

    .container {
        padding: 0;
    }

    .title {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        padding: 0 1rem;
    }

    .grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .problemsContainer {
        gap: 1rem;
    }

    .problemCard {
        padding: 1.25rem;
        margin: 0 0.5rem;
    }

    .cardHeader {
        gap: 0.75rem;
    }

    .cardTitle {
        font-size: 1.1rem;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .cardText {
        font-size: 0.95rem;
    }

    .solutionsCard {
        margin: 0 0.5rem;
        padding: 1.5rem;
    }

    .solutionsTitle {
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }

    .solutionsList {
        gap: 1rem;
    }

    .solutionItem {
        gap: 0.75rem;
    }

    .solutionContent h4 {
        font-size: 1rem;
    }

    .solutionContent p {
        font-size: 0.9rem;
    }

    .nextSection {
        margin-top: 2.5rem;
    }

    .nextSectionLink {
        font-size: 0.95rem;
        padding: 0.5rem 1rem;
    }
}

@media (max-width: 375px) {
    .title {
        font-size: 1.5rem;
    }

    .problemCard {
        padding: 1rem;
    }

    .cardTitle {
        font-size: 1rem;
    }

    .cardText {
        font-size: 0.9rem;
    }

    .solutionsCard {
        padding: 1rem;
    }

    .solutionContent h4 {
        font-size: 0.95rem;
    }

    .solutionContent p {
        font-size: 0.85rem;
    }
}
.nextSectionLink {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    background: #fca311;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.nextSectionLink:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(252, 163, 17, 0.4);
    background: #ff9800;
}

.nextSectionLink:active {
    transform: translateY(1px);
}

/* Ikonka w buttonie */
.nextSectionLink svg {
    transition: transform 0.3s ease;
}

.nextSectionLink:hover svg {
    transform: translateX(5px);
}

/* Style mobilne */
@media (max-width: 768px) {
    .nextSectionLink {
        padding: 0.75rem 1.5rem;
        font-size: 0.9rem;
        gap: 0.5rem;
    }
}